import { ContactLinks, copyrightText } from "@/constants";
import Image from "next/image";
import styles from "../../css/modules/footer.module.scss";
const Contact = () => {
    return (
        <>
            <div className='bg-[url("/home/ellipse33.svg")] bg-no-repeat bg-left-top'>
            </div>
            <div
                className={`relative bg-[url("/Images/Footer/footer.png")] bg-no-repeat bg-cover text-white  py-5 md:py-20 ${styles.mainfooter}`}
            >
                <div className={`mx-auto max-w-7xl flex flex-col-reverse items-center gap-4 md:flex-row md:justify-between mb-20 mx-auto w-full sm:w-11/12 md:w-10/12 lg:w-9/12 ${styles.socialcontainer}`}>
                    <div>
                        <div className="flex mt-2">
                            <Image
                                height={45}
                                width={165}
                                src="/Images/Footer/tacnique.png"
                            />
                        </div>
                        <div className="flex gap-2 relative">
                            {ContactLinks.socialMediaLinks.map(
                                (value, index) => {
                                    return (
                                        <a
                                            key={index}
                                            className="mt-4"
                                            href={value.link}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            data-test-id={value.name}
                                        >
                                            <Image
                                                src={value.icon}
                                                alt={value.name}
                                                className="w-8 mt-2"
                                                width={33}
                                                height={33}
                                                loading="lazy"
                                            />
                                        </a>
                                    );
                                }
                            )}
                        </div>
                        <p
                            className={`mt-5 text-md text-justify ${styles.copyrightTextdesktop}`}
                            data-test-id="copyright"
                        >
                            &copy; {copyrightText}
                        </p>
                    </div>
                    <div className={styles.browseSection}>
                        <div className="flex-shrink-0  mx-auto text-center md:mx-0 md:text-left text-white opacity-90">
                            <h3 className="text-[24px] font-extrabold">
                                Browse
                            </h3>
                            <div className="flex flex-col space-y-3 my-2 text-left">
                                {ContactLinks.browseLinks.map((browse) => (
                                    <a href={browse.link} key={browse.link}>
                                        <p className="text-md">{browse.name}</p>
                                    </a>
                                ))}
                            </div>
                        </div>
                        <div className="flex-shrink-0  mx-auto text-left md:mx-0 md:text-left text-white opacity-90">
                            <h3 className="text-[24px] font-extrabold">
                                Contact
                            </h3>
                            <div className="flex flex-col space-y-3 my-2">
                                <p className="space-x-2 text-md flex items-center text-justify">
                                    <Image
                                        className="w-5 h-5"
                                        src={"/Images/Footer/call.png"}
                                        alt="phone"
                                        width={20}
                                        height={20}
                                        loading="lazy"
                                    />
                                    <a href="tel:+1 650 360 2410">
                                        +1 650 360 2410
                                    </a>
                                    <span className="text-xs">
                                        <span className={`text-xs ${styles.flag2}`}>
                                            <Image
                                                className="w-5 h-5"
                                                src={"/logos/phone-flag-3.png"}
                                                alt="phone-flag-3"
                                                width={20}
                                                height={20}
                                                loading="lazy"
                                            />
                                        </span>
                                    </span>
                                </p>
                                <p className="space-x-2 text-sm flex items-center text-justify">
                                    <Image
                                        className="w-5 h-5"
                                        src={"/Images/Footer/call.png"}
                                        alt="phone"
                                        width={20}
                                        height={20}
                                        loading="lazy"
                                    />
                                    <a href="tel:+971 58 548 1478">
                                        +971 58 548 1478
                                    </a>
                                    <span className="text-xs">
                                        <span className={`ml-2 text-xs ${styles.flag}`}>
                                            <Image
                                                className="w-5 h-5"
                                                src={"/logos/phone-flag-1.png"}
                                                alt="phone-flag-1"
                                                width={20}
                                                height={20}
                                                loading="lazy"
                                            />
                                        </span>
                                    </span>
                                </p>
                                <p className="space-x-2 text-sm flex items-center text-justify">
                                    <Image
                                        className=" w-5 h-5"
                                        src={"/Images/Footer/call.png"}
                                        alt="phone"
                                        width={20}
                                        height={20}
                                        loading="lazy"
                                    />
                                    <a href="tel:+91 916 756 9834">
                                        +91 916 756 9834
                                    </a>
                                    <span className="text-xs">
                                        <span className={`ml-2 text-xs ${styles.flag}`}>
                                            <Image
                                                className="w-5 h-5"
                                                src={"/logos/phone-flag-2.png"}
                                                alt="phone-flag-2"
                                                width={20}
                                                height={20}
                                                loading="lazy"
                                            />
                                        </span>
                                    </span>
                                </p>
                                <p className="space-x-2  flex items-center">
                                    <Image
                                        className=" w-5 h-5"
                                        src={"/logos/mail-sm.svg"}
                                        alt="mail"
                                        width={20}
                                        height={20}
                                        loading="lazy"
                                    />
                                    <a
                                        href="mailto:contact@tacnique.com"
                                        className="text-md text-justify "
                                    >
                                        contact@tacnique.com
                                    </a>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={`${styles.footerTagContainer}`}>
                    <div
                        className={`flex mb-0 justify-between mx-auto max-w-7xl py-6 mx-auto w-full sm:w-11/12 md:w-10/12 lg:w-9/12 `}
                    >
                        <div>
                            <p
                                className={`text-md text-justify ${styles.copyrightTextmobile}`}
                                data-test-id="copyright"
                            >
                                &copy; {copyrightText}
                            </p>
                        </div>
                        <div className={`flex ${styles.footerTag}`}>
                            {ContactLinks.supportLinks.map((support) => (
                                <a href={support.link} key={support.link}>
                                    <p className="text-md">{support.name}</p>
                                </a>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
export default Contact;
